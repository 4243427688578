import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Introduction`}</h3>
    <p>{`Voice acting plays a crucial role in the success of video games. The quality of a game’s voice over
significantly impacts a player’s overall experience.`}</p>
    <p>{`Game developers have long relied on text-to-speech (TTS) technology to make the characters sound more
realistic and engaging. However, until now, most TTS technology has been limited, unable to produce
expressive and natural-sounding voices. However, this is all about to change.`}</p>
    <p>{`Today, we’re excited to announce the launch of XTTS, a new TTS technology explicitly designed for game
developers. XTTS delivers the most expressive and realistic TTS performances, taking game audio to the
next level. `}<a parentName="p" {...{
        "href": "https://docs.coqui.ai/docs#xtts"
      }}>{`Try it out now through our API`}</a>{`, or just hear the
difference:`}</p>
    <div align="center">
  <iframe style={{
        "width": "640px",
        "height": "360px",
        "borderWidth": "0px 0px 0px 0px",
        "borderStyle": "solid solid solid solid",
        "borderColor": "black black black black"
      }} src="https://youtube.com/embed/TbgzSOgM-p0?autoplay=1&loop=1&playlist=TbgzSOgM-p0" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <h3 {...{
      "id": "research",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#research",
        "aria-label": "research permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Research`}</h3>
    <p>{`XTTS is a new technology inspired by the latest developments in the Generative AI space. XTTS has very
expressive outputs, better voice cloning, and delivers all the enhanced Coqui Studio features.
It’s next level! This new technology is designed specifically for game developers who want their
characters to sound more realistic and captivating.`}</p>
    <p>{`XTTS takes inspiration from large language models but focuses on delivering exceptional TTS performance.
It is compatible with Coqui Studio 🐸, including prompt-to-voice and voice cloning. Furthermore, XTTS
boasts superior voice cloning, enhanced studio capabilities, and improved prompt-to-voice performance
relative to its predecessors.`}</p>
    <p>{`With XTTS, game developers can now create more realistic and expressive voices for their characters.`}</p>
    <h3 {...{
      "id": "limitations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#limitations",
        "aria-label": "limitations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Limitations`}</h3>
    <p>{`XTTS, while highly advanced, is slower than our core TTS technology. (XTTS takes more time, but produces
higher quality results!) Nevertheless, we will continue to make our core TTS technology available for
real-time use cases, as well as for our open-source users. It is still state-of-the-art when considering
the trade-off between speed and quality.`}</p>
    <h3 {...{
      "id": "future-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#future-work",
        "aria-label": "future work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Future Work`}</h3>
    <p>{`In the upcoming months, we will be introducing Coqui Studio integration of XTTS, adding new languages
to our platform, speeding up XTTS, and further improving its performance. Stay tuned for more information
on the upcoming language updates. In the meantime, please `}<a href="mailto:info@coqui.ai?subject=XTTS Languages">{`let us know`}</a>{`
if you have a particular language in mind that you would like us to prioritize.`}</p>
    <h3 {...{
      "id": "ending-words",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ending-words",
        "aria-label": "ending words permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Ending Words`}</h3>
    <p>{`XTTS is a game-changer for game developers. Creating realistic and expressive voices for characters,
XTTS is more human than human. With its advanced features and capabilities, XTTS delivers the best
TTS performance for game development. We encourage you to
`}<a parentName="p" {...{
        "href": "https://docs.coqui.ai/docs#xtts"
      }}>{`try out XTTS through our API`}</a>{`
and let us know your thoughts.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      